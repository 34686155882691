<div [ngClass]="[radioWrapperDefaultClass, radioWrapperCustomClass]">
    <span [ngClass]="[disabledRadioLabelClass]">
        <p
            *ngIf="floatingLabelText && floatingLabelText.toString().toLowerCase().trim().length > 0"
            [ngClass]="floatingLabelTextClass"
        >
            {{ floatingLabelText }}
        </p>
        <div [ngClass]="radioFieldContainerClass">
            @for (radioField of radioFieldOptions; let radioFieldIndex = $index; track
            ('radioField_'+radioFieldIndex+'_'+radioBtnId)){
            <div>
                <input
                    type="radio"
                    [name]="name"
                    [value]="radioField.value"
                    [id]="'radioField_' + radioFieldIndex + '_' + radioBtnId"
                    [ngClass]="[
                        radioButtonDefaultClass,
                        radioButtonCustomClass,
                        disabledRadioButtonClass,
                        radioFieldClasses
                    ]"
                    [formControl]="control"
                    [disabled]="isDisabled"
                />

                <span [ngClass]="[radioLabelTextWrapperDefaultClass, radioLabelTextWrapperCustomClass]">
                    <label
                        [ngClass]="[
                            radioLabelTextDefaultClass,
                            radioLabelTextCustomClass,
                            disabledRadioLabelTextClass
                        ]"
                        [for]="'radioField_' + radioFieldIndex + '_' + radioBtnId"
                        >{{ radioField.label }}</label
                    >
                    <a
                        *ngIf="showDetails"
                        [ngClass]="[detailsLinkDefaultClass, detailsLinkCustomClass, disabledDetailsClass]"
                        >See Details</a
                    >
                </span>
            </div>
            }
        </div>
    </span>
</div>

<ng-container *ngIf="control.invalid && _showErrorMessage">
    <p class="error-message-spacing" *ngFor="let err of control.errors | keyvalue" [ngClass]="[errorMessageClass]">
        {{ errorMessages[err.key] }}
    </p>
</ng-container>
