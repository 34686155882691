import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EXIT_SITE_CONF_QUERY } from '../queries/exit-site-confirmation.query';

@Injectable({
    providedIn: 'root',
})
export class ExitPopupService {
    constructor(private apollo: Apollo) {}

    getPopupData(languageCode: any): Observable<any> {
        return this.apollo
            .watchQuery<any>({
                query: EXIT_SITE_CONF_QUERY,
                variables: {
                    language: languageCode,
                },
            })
            .valueChanges.pipe(map((result) => result.data.genericPopups.nodes));
    }
}
