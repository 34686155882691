import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldErrorMessages } from '../corporate-form-field/corporate-form-field.component';

@Component({
    selector: 'ua2-corporate-radio-button, aff-corporate-radio-button',
    templateUrl: './corporate-radio-button.component.html',
})
export class CorporateRadioButtonComponent {
    @Input() name = '';
    @Input() value = '';
    @Input() isDisabled = false;
    @Input() radioButtonDefaultClass = 'aff-radio-input';
    @Input() radioButtonCustomClass = '';
    @Input() radioLabelTextDefaultClass = 'aff-radio-label-text';
    @Input() radioLabelTextCustomClass = '';
    @Input() formControlName: string;
    @Input() control: FormControl = new FormControl();
    @Input() labelText: string = '';
    @Input() radioWrapperDefaultClass = 'aff-radio-wrapper';
    @Input() radioWrapperCustomClass = '';
    @Input() showDetails = false;
    @Input() radioLabelTextWrapperDefaultClass = 'aff-radio-label-text-wrapper';
    @Input() radioLabelTextWrapperCustomClass = '';
    @Input() detailsLinkDefaultClass = 'aff-details-link';
    @Input() detailsLinkCustomClass = '';
    @Input() radioFieldOptions = [{ label: '', value: '' }];
    @Input() radioBtnId = '';
    @Input() floatingLabelText = '';
    @Input() floatingLabelTextClass = '';
    @Input() radioFieldContainerClass = 'radio-field-container';
    @Input() errorMessageClass?: string = 'text-start text-red m-0';
    @Input() errorMessages: FormFieldErrorMessages = {};
    _showErrorMessage: any = true;

    @Input()
    set showErrorMessage(value: any) {
        this._showErrorMessage = value;
    }

    get disabledRadioButtonClass() {
        if (this.isDisabled) {
            if (this.value == this.control.value) {
                return 'aff-radio-input-disabled-checked';
            }
            return 'aff-radio-input-disabled-unchecked';
        }
        return '';
    }

    get disabledRadioLabelClass() {
        return this.isDisabled ? 'aff-radio-label-disabled' : '';
    }

    get disabledRadioLabelTextClass() {
        return this.isDisabled ? 'aff-radio-label-text-disabled' : '';
    }

    get disabledDetailsClass() {
        return this.isDisabled ? 'aff-details-link-disabled' : '';
    }

    get radioFieldClasses(): string {
        let classes = `${this.radioButtonDefaultClass} ${this.radioButtonCustomClass}`;

        if (this._showErrorMessage) {
            classes += ' error-message';
        }
        return classes.trim();
    }
}
