import { FormFieldOptions } from './corporate-form-field.component';

export class FormFieldMethods {
    static showOptions(options: FormFieldOptions[], value: string | number): any[] {
        let filteredOptions, showSearchDropdown;
        if (options) {
            filteredOptions = options.filter((option) =>
                option.value.toLowerCase().includes((value as string).toLowerCase())
            );
            showSearchDropdown =
                (isNaN(value as number) && (value as string).length > 1) ||
                (!isNaN(value as number) && (value as string).length > 0);
        }
        return [filteredOptions, showSearchDropdown];
    }

    static highlightMatching(option: FormFieldOptions, value: string): string {
        if (option.label) {
            const index = option.label.toLowerCase().indexOf(value.toLowerCase());
            if (index === -1) {
                return option.label;
            } else {
                const matchingPart = option.label.slice(index, index + value.length);
                const restPart = option.label.slice(index + value.length);
                return `${option.label.slice(
                    0,
                    index
                )}<strong class="highlight">${matchingPart}</strong>${restPart}`;
            }
        }
        return '';
    }

    static getFormFieldLabel(type: any, label: any, options: any): string {
        const count = options.filter((item: any) => item.checked);
        if (type && type === 'multiselect' && count && count.length > 0) {
            return `${label} (${count.length})`;
        }
        return label;
    }

    static formFieldClasses(
        defaultClass: any,
        customClass: any,
        type: any,
        value: any,
        showErrorMsg: boolean,
        singleSelectDropdown: boolean,
        multiSelectDropDown: boolean
    ): string {
        let classes = `${defaultClass} ${customClass}`;
        if (type?.toLowerCase() === 'multiselect' && value.length > 0) {
            classes += ' multi-select-form-control';
        }
        if (showErrorMsg) {
            classes += ' error-message';
        }
        if (type?.toLowerCase() === 'multiselect') {
            classes += ' pe-10';
        }
        if (singleSelectDropdown || multiSelectDropDown) {
            classes += ` form-field-border`;
        } else {
            classes += ` `;
        }
        return classes.trim();
    }

    static getCheckedListItems(_multiSelectOptions: any, checkedList: any, checkedListLabel: any): any {
        for (const option of _multiSelectOptions) {
            if (option.checked) {
                if (checkedList.indexOf(option.value.toLowerCase()) === -1) {
                    checkedList.push(option.value);
                    checkedListLabel.push(option.label);
                }
            } else {
                const index = checkedList.indexOf(option.value.toLowerCase());
                if (index !== -1) {
                    checkedList.splice(index, 1);
                    checkedListLabel.splice(index, 1);
                }
            }
        }
        return [checkedList, checkedListLabel];
    }

    static setCheckedListItems(options: any, checkedList?: any): any {
        return options.map((option: any) => {
            option.checked = checkedList.includes(option.value);
            return option;
        });
    }

    static formFieldLayoutClasses(multiSelectOverlayClass: string | undefined) {
        return `${multiSelectOverlayClass}`.trim();
    }
}
