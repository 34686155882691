<div [ngClass]="[checkBoxContainerDefaultClass, checkBoxContainerCustomClass]">
    @if(useNgModel){
    <input
        [ngClass]="[checkBoxDefaultClass, checkBoxCustomClass]"
        type="checkbox"
        [id]="checkBoxId"
        [(ngModel)]="isChecked"
        (change)="onChange()"
        [disabled]="isDisabled"
    />
    }@else{
    <input
        [ngClass]="[checkBoxDefaultClass, checkBoxCustomClass, checkBoxFieldClasses]"
        type="checkbox"
        [id]="checkBoxId"
        [formControl]="control"
        (change)="onFormControlChange()"
        [disabled]="isDisabled"
    />
    }

    <label [ngClass]="[checkBoxLabelDefaultClass, checkBoxLabelCustomClass]" [for]="checkBoxId">
        {{ checkBoxLabel }}
    </label>
</div>
<ng-container *ngIf="control.invalid && _showErrorMessage">
    <p class="error-message-spacing" *ngFor="let err of control.errors | keyvalue" [ngClass]="[errorMessageClass]">
        {{ errorMessages[err.key] }}
    </p>
</ng-container>
