// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.interface';

export const environment: Environment = {
    production: false,
    graphQLURL: 'https://dev1hcms1.americanfirstfinance.com/graphql',
    GTM_ID: 'GTM-TLXDWB2',
    wordpressBaseURL: 'https://dev1hcms1.americanfirstfinance.com',
    findStoreURL: 'https://sv7.americanfirstfinance.com/locations',
    launchDarklyClientID: '636124d2cfc7160bf99cedda',
    imagesPath: 'https://acdn.americanfirstfinance.com/',
    userName: 'aff_preview',
    password: 'O^pM*ub%)i&KWLd6N!VE9fBu', //NOSONAR
    merchantSearchURL: 'https://sv12.americanfirstfinance.com/api/search-locations/',
    locationsBaseURL: 'https://sv12.americanfirstfinance.com/api/',
    SOPSearchURL: 'https://sv12.americanfirstfinance.com/api/search/',
    nearByLocationsURL: 'https://sv12.americanfirstfinance.com/api/nearby/',
    snapEngageScript:
        'https://storage.googleapis.com/code.snapengage.com/js/40f47291-3d6a-446a-bafa-dab2afe53444.js',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
