import { env } from '@env';

export const ONE_TRUST_COOKIE_SCRIPT = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
export const ONE_TRUST_DATA_DOMAIN_SCRIPT = '33bc1aea-37e6-4baf-966e-da1e9290c448';
export const ONE_TRUST_DATA_DOMAIN_CONSET = 'https://cdn.cookielaw.org/consent/';
export const SNAPENGAGE_CHAT_SCRIPT =
    'https://storage.googleapis.com/code.snapengage.com/js/c48360c9-f4c2-4ed4-9055-4fd2fa24dfc0.js';
export const GOOGLE_MAP_API_KEY = env.GMAP_API_KEY;
export const GOOGLE_MAPS_PLACE_URL = 'https://www.google.com/maps/place/';
export const GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';
export const BREAD_SDK_URL = 'https://connect-preview.breadpayments.com/sdk.js'; // env.BREAD_SDK_URL
