import { gql } from 'apollo-angular';

export const EXIT_SITE_CONF_QUERY = gql`
    query genericPopups($language: String) {
        genericPopups(where: { language: $language }) {
            nodes {
                genericPopup {
                    fieldGroupName
                    popUp {
                        popUpModule
                        activatePopUp
                        fieldGroupName
                        popUpCancelButton
                        popUpContinueButton
                        popUpText
                        popUpTitle
                        showCancelButton
                        showContinueButton
                    }
                }
            }
        }
    }
`;
