import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { GFORM_DATA_QUERY } from '../queries/data.query';

const checkBoxFormFields = [
    {
        name: 'checkboxfield',
        type: 'checkbox',
    },
    {
        name: 'consentfield',
        type: 'checkbox',
    },
];

@Injectable({
    providedIn: 'root',
})
export class NativeFormService {
    structuredGFormData: any = {};
    loopIndex = 0;
    hiddenNodes = [];
    paginations = false;
    acrdnNonRequiredFields = ['fax', 'phone'];
    checkBoxFormFields = checkBoxFormFields;
    formModuleId: any;
    formModuleIndex: any;
    reCaptchaStates: BehaviorSubject<boolean>[] = [];
    reCaptchaCount: number = 0;
    hiddenFieldName = 'linked sugar acct id';

    constructor(private _apollo: Apollo) {}

    getStructuredGFormData(response: any, formModuleId: any, formModuleIndex: any) {
        this.structuredGFormData = {};
        this.loopIndex = 0;
        this.hiddenNodes = [];
        this.paginations = false;
        this.formModuleId = undefined;
        this.formModuleIndex = undefined;
        const data = response?.data;
        if (data) {
            this.formModuleId = formModuleId;
            this.formModuleIndex = formModuleIndex;
            const gFormsData = this.getGFormsData(data.gfForms?.nodes);

            this.structuredGFormData['gFormsData'] = gFormsData ? {} : undefined;
            this.structuredGFormData['gFormSettings'] = data.gfSettings || {};

            if (gFormsData) {
                this.getGFormPageInformations(gFormsData);
                this.getGFormMiscellaneousDatas(gFormsData);
                return this.structuredGFormData;
            }
            return null;
        }
        return null;
    }

    getGFormsData(data: any): any[] | null {
        return data
            ? data.map((obj: any) => ({
                  title: obj?.title,
                  description: obj?.description,
                  formId: obj?.formId,
                  isActive: obj?.isActive,
                  pagination: obj?.pagination,
                  formFields: obj?.formFields,
                  lastPageButton: obj?.lastPageButton,
                  submitButton: obj?.submitButton,
              }))
            : null;
    }

    getGFormPageInformations(gFormsData: any): void {
        const data = gFormsData?.[0];
        const formData = this.structuredGFormData['gFormsData'];

        formData['formPageInformations'] = formData['formPageInformations'] || {};

        formData['formPageInformations']['totalPages'] = data?.pagination?.pageNames?.length || 1;
        formData['formPageInformations']['pagination'] = formData['formPageInformations']['totalPages'] > 1;
        this.paginations = formData['formPageInformations']['totalPages'] > 1;

        this.getGFormPages(gFormsData, formData['formPageInformations']['pagination']);
    }

    getGFormPages(gFormsData: any, paginations: boolean) {
        const formPageInformations = this.structuredGFormData['gFormsData']['formPageInformations'];
        formPageInformations['formPages'] = [];

        this.populateGFormPages(gFormsData, paginations);
    }

    populateGFormPages(gFormsData: any, paginations: boolean) {
        const data = gFormsData[0];
        this.loopIndex = 0;

        if (paginations) {
            data?.pagination?.pageNames?.forEach((pageName: any, pageIndex: number) => {
                const obj: any = this.getGFormPageData(gFormsData, paginations, pageName, pageIndex);
                this.structuredGFormData['gFormsData']['formPageInformations']['formPages'].push(obj);
            });
            this.loopIndex = 0;
        } else {
            this.loopIndex = 0;
            const obj: any = this.getGFormPageData(gFormsData, paginations);
            this.structuredGFormData['gFormsData']['formPageInformations']['formPages'].push(obj);
        }
    }

    getGFormPageData(gFormsData: any, paginations: boolean, pageName?: any, pageIndex?: any) {
        //NOSONAR
        const data = gFormsData?.[0];
        const totalPage = this.structuredGFormData['gFormsData']['formPageInformations']['totalPages'];
        const nodes = data?.formFields?.nodes?.filter((node: any) => node?.type?.toLowerCase() !== 'hidden');
        this.hiddenNodes = data?.formFields?.nodes?.filter((node: any) => node?.type?.toLowerCase() === 'hidden');

        const obj: any = {
            pageTitle: pageName || '',
            pageSections: [],
            pageConsent: null,
            pageButton: null,
            pageCustomField: null,
        };
        let currentSection: any = null;
        let isSubSection = false;
        for (let i = this.loopIndex; i < nodes.length; i++) {
            const node = nodes[i];
            let nextItem: any;
            let prevItem: any;
            if (i + 1 < nodes.length) {
                nextItem = nodes[i + 1];
            }
            if (i - 1 >= 0) {
                prevItem = nodes[i - 1];
            }
            if (paginations) {
                if (node?.type?.toLowerCase() === 'page') {
                    if (pageIndex + 1 < totalPage) {
                        obj.pageButton = node;
                    }
                    /**
                     * 
                     *@description commenting out below code for future usage
                    else {
                        obj.pageButton = {
                            previousButton: data?.lastPageButton,
                            nextButton: data?.submitButton,
                        };
                    }
                    */
                    this.loopIndex = i + 1;
                    break;
                }
            }

            if (
                node?.type?.toLowerCase() === 'section' &&
                (i === 0 || (prevItem && prevItem?.type?.toLowerCase() !== 'html')) &&
                nextItem &&
                nextItem?.type?.toLowerCase() !== 'post_custom_field'
            ) {
                isSubSection = false;
                currentSection = { ...node, sectionFields: [], subSections: [] };

                // Properties related to styling and display
                currentSection['sectionClass'] = this.getCardSectionClass(
                    currentSection['layoutGridColumnSpan'] || 12
                );
                currentSection['sectionTitleClass'] = this.getCardSectionTitleClass(node);
                currentSection['infoTextClass'] = this.getInfoTextClass();
                currentSection['toggleActiveIconClass'] = this.getToggleActiveIconClass();
                currentSection['toggleInactiveIconClass'] = this.getToggleInactiveIconClass();
                currentSection['sectionSubTitleClass'] = this.getCardSubsectionTitleClass();
                currentSection['sectionDividerClass'] = this.sectionDividerClass();
                currentSection['sectionDisplay'] = this.isSectionDisplay(node);

                // Properties related to section hierarchy
                const [sectionChild, parentId] = this.isSectionChild(node);
                currentSection['sectionChild'] = sectionChild;
                currentSection['parentId'] = parentId;

                // Properties related to section hierarchy
                const [sectionAccordion, accordionNumber] = this.isSectionAccordion(node);
                currentSection['sectionAccordion'] = sectionAccordion;
                currentSection['accordionNumber'] = accordionNumber;
                currentSection['accordionOpen'] = false;
                obj.pageSections.push(currentSection);
            } else if (
                node?.type?.toLowerCase() === 'html' &&
                (i === 0 || (prevItem && prevItem?.type?.toLowerCase() !== 'section')) &&
                nextItem &&
                nextItem?.type?.toLowerCase() !== 'section'
            ) {
                isSubSection = true;
                currentSection['subSections'].push({ ...node, subSectionFields: [] });
                const subSection = currentSection['subSections'][currentSection['subSections'].length - 1];
                const [sectionChild, parentId] = this.isSectionChild(node);
                subSection['subSectionChild'] = sectionChild;
                subSection['subSectionParentId'] = parentId;

                // Properties related to styling and display
                subSection['subSectionTitleClass'] = this.getSubSectionTitleClass(node);
                subSection['subSectionSubTitleClass'] = this.getCardSubSectionSubTitleClass();
            } else if (
                node?.type?.toLowerCase() === 'section' &&
                i >= 0 &&
                nextItem &&
                nextItem?.type?.toLowerCase() === 'post_custom_field'
            ) {
                isSubSection = false;
                currentSection = null;

                const sectionClass = `${this.getCardSectionClass(node?.layoutGridColumnSpan || 12)} pb-10`;
                const sectionTitleClass = this.getCardSectionTitleClass(node, 'post_custom_field');
                const sectionSubTitleClass = this.getCardSubsectionTitleClass();

                obj.pageCustomField = {
                    ...node,
                    customFields: nextItem,
                    sectionClass: sectionClass,
                    sectionTitleClass: sectionTitleClass,
                    sectionSubTitleClass: sectionSubTitleClass,
                };
            } else if (node?.type?.toLowerCase() === 'consent') {
                isSubSection = false;
                currentSection = null;
                const pageConsent = this.manipulateFormFieldProperties(node);

                const sectionClass = `${this.getCardSectionClass(node?.layoutGridColumnSpan || 12)} pb-10`;
                const sectionTitleClass = `${this.getCardSubsectionTitleClass()} fw-bold mb-2 text-start`;
                const sectionSubTitleClass = `${this.getCardSubsectionTitleClass()} text-start`;
                const sectionFieldLabel = 'I Agree';

                obj.pageConsent = {
                    ...pageConsent,
                    sectionClass: sectionClass,
                    sectionTitleClass: sectionTitleClass,
                    sectionSubTitleClass: sectionSubTitleClass,
                    sectionFieldLabel: sectionFieldLabel,
                };
            } else {
                const allowedTypes = ['html', 'page', 'consent', 'post_custom_field', 'section'];

                if (!allowedTypes.includes(node?.type?.toLowerCase())) {
                    if (currentSection) {
                        if (isSubSection === false) {
                            const formField = this.manipulateFormFieldProperties(node);
                            currentSection.sectionFields.push(formField);
                        } else {
                            const subSection =
                                currentSection['subSections'][currentSection['subSections'].length - 1];
                            const formField = this.manipulateFormFieldProperties(node);
                            subSection['subSectionFields'].push(formField);
                        }
                    } else {
                        isSubSection = false;
                        const formField = this.manipulateFormFieldProperties(node);
                        currentSection = { type: 'section', sectionFields: [formField], subSections: [] };

                        // Properties related to styling and display
                        currentSection['sectionClass'] = this.getCardSectionClass(
                            currentSection['layoutGridColumnSpan'] || 12
                        );
                        currentSection['sectionTitleClass'] = this.getCardSectionTitleClass(node);
                        currentSection['infoTextClass'] = this.getInfoTextClass();
                        currentSection['toggleActiveIconClass'] = this.getToggleActiveIconClass();
                        currentSection['toggleInactiveIconClass'] = this.getToggleInactiveIconClass();
                        currentSection['sectionSubTitleClass'] = this.getCardSubsectionTitleClass();
                        currentSection['sectionDividerClass'] = this.sectionDividerClass();
                        currentSection['sectionDisplay'] = this.isSectionDisplay(node);

                        // Properties related to section hierarchy
                        const [sectionChild, parentId] = this.isSectionChild(node);
                        currentSection['sectionChild'] = sectionChild;
                        currentSection['parentId'] = parentId;

                        // Properties related to section hierarchy
                        const [sectionAccordion, accordionNumber] = this.isSectionAccordion(node);
                        currentSection['sectionAccordion'] = sectionAccordion;
                        currentSection['accordionNumber'] = accordionNumber;
                        currentSection['accordionOpen'] = false;
                        obj.pageSections.push(currentSection);
                    }
                }
            }
        }
        if (!paginations) {
            obj.pageButton = {
                previousButton: null,
                nextButton: data?.submitButton,
            };
        }
        if (paginations && pageIndex + 1 === totalPage) {
            obj.pageButton = {
                previousButton: data?.lastPageButton,
                nextButton: data?.submitButton,
            };
        }

        return obj;
    }

    getGFormMiscellaneousDatas(gFormsData: any): void {
        const data = gFormsData?.[0];

        if (data) {
            const formData = this.structuredGFormData['gFormsData'];
            formData['formTitle'] = data.title;
            formData['formDescription'] = data.description;
            formData['formId'] = data.formId;
            formData['isActiveForm'] = data.isActive;
        }
    }

    getCardSectionClass(column: any) {
        return `d-block col-12 col-md-${column} m-0 mt-4 corporate-card-container corporate-card-container-spacing `;
    }

    getCardSectionTitleClass(node?: any, customField?: string) {
        let classes = 'mb-0 ';
        if (!customField) {
            if (this.paginations) {
                if (!node?.description) {
                    classes = 'mb-3 mb-md-4 ';
                }
            } else {
                if (!node?.description) {
                    //NOSONAR
                    classes = 'mb-3 mb-xxl-8 ';
                }
            }
        }
        return `card-section-title ${classes} `;
    }
    getInfoTextClass() {
        return `info-text-class text-gap `;
    }

    getToggleActiveIconClass() {
        return `fa-regular fa-minus toggle-icon `;
    }

    getToggleInactiveIconClass() {
        return `fa-regular fa-plus toggle-icon `;
    }

    getCardSubsectionTitleClass() {
        return 'card-section-sub-title ';
    }

    getCardSubSectionSubTitleClass() {
        return 'card-sub-section-sub-title ';
    }

    sectionDividerClass() {
        return 'section-divider ';
    }

    getSubSectionTitleClass(subsection: any, customField?: string) {
        let classes = 'mb-0 ';
        if (!customField) {
            if (this.paginations) {
                if (!subsection?.content) {
                    classes = 'mb-3 mb-md-4 ';
                }
            } else {
                if (!subsection?.content) {
                    //NOSONAR
                    classes = 'mb-3 mb-xxl-8 ';
                }
            }
        }
        return `sub-section-title ${classes} `;
    }

    manipulateFormFieldProperties(field: any) {
        const formField: any = this.manipulateFieldInputTypes(field);

        /**
         * @description Add states for captcha fields.
         */
        if (field?.__typename?.toString()?.trim()?.toLowerCase() === 'captchafield') {
            this.addReCaptchaState(false);
        }

        const obj: any = {};

        obj['formFieldName'] = this.getFormFieldName(formField);

        obj['formFieldValue'] = this.getFormFieldValues(formField);
        obj['formFieldCustomLabelText'] = this.getFormFieldCustomLabelText(formField);
        const errObj: any = this.manipulateErrorValidators(formField);
        obj['formFieldValidators'] = errObj?.formFieldValidators;
        obj['formFieldErrorMessages'] = errObj?.formFieldErrorMessages;
        obj['formFieldAutoFillCheckBox'] = this.isFormFieldAutoFillCheckBox(formField);
        obj['formFieldMask'] = this.getFieldMask(formField);
        /**
         * @description commenting out below code for future usage
         * obj['formFieldContainerClass'] = this.getFormFieldContainerClass(formField);
         */
        obj['formFieldClass'] = this.getFormFieldClass(formField);
        obj['formFieldFloatingLabelTextClass'] = this.formFieldFloatingLabelTextClass(formField);
        obj['formFieldInputContainerClass'] = this.getFormFieldInputContainerClass(formField);
        obj['formFieldInputClass'] = '';
        obj['formFieldInputLabelClass'] = this.getFormFieldInputLabelClass(formField);
        const hideInputText: any = this.hideFormFieldInputText(formField);
        obj['formFieldInputTextHide'] = hideInputText?.hideFormFieldText;
        obj['isPasswordType'] = hideInputText?.isPasswordType;
        return { ...obj, ...this.manipulateFieldData(formField), ...formField };
    }

    getFormFieldName(field: any) {
        return `${field?.id}_${field?.__typename?.toString().toLowerCase()}_${this.formModuleId}_${
            this.formModuleIndex
        }`;
    }

    getFormFieldValues(formField: any) {
        if (formField?.__typename?.toString().toLowerCase() === 'radiofield') {
            const defaultSelectedValue = formField?.choices?.find((choice: any) => {
                return choice?.isSelected === true;
            });

            if (defaultSelectedValue) {
                return defaultSelectedValue?.value;
            }
            return '';
        } else {
            if (formField?.value) {
                return formField?.value;
            }
            return '';
        }
    }

    getFormFieldCustomLabelText(field: any) {
        if (
            field?.__typename?.toString().toLowerCase() === 'selectfield' &&
            field?.label?.toString()?.trim()?.length > 0 &&
            field?.labelPlacement?.toString()?.toLowerCase() === 'inherit'
        ) {
            return this.selectFieldCustomLabel(field);
        }
        return null;
    }

    selectFieldCustomLabel(field: any) {
        const label = field?.choices.find((choice: any) => {
            return (
                choice?.text?.toString()?.trim()?.toLowerCase()?.includes('select') ||
                choice?.text?.toString()?.trim()?.toLowerCase() === 'select'
            );
        });

        if (label) {
            return label?.text;
        }

        return '';
    }

    manipulateFieldData(field: any) {
        if (field?.__typename?.toString().toLowerCase() === 'selectfield') {
            return this.manipulateSelectField(field);
        }
        if (field?.__typename?.toString().toLowerCase() === 'radiofield') {
            return this.manipulateRadioField(field);
        }
        if (field?.__typename?.toString().toLowerCase() === 'checkboxfield') {
            return this.manipulatCheckboxField(field);
        }
        return {};
    }

    manipulateSelectField(field: any) {
        const selectOptions: any = this.getFormFieldOptions(field)?.filter((option: any) => {
            return !(
                option?.label?.toString()?.trim()?.toLowerCase()?.includes('select') ||
                option?.label?.toString()?.trim()?.toLowerCase() === 'select'
            );
        });

        if (field?.labelPlacement && field?.labelPlacement?.toString()?.trim()?.toLowerCase() === 'inherit') {
            const defaultOption: any = this.getFormFieldOptions(field)?.find(
                (option: any) =>
                    option?.label?.toString()?.trim()?.toLowerCase()?.includes('select') ||
                    option?.label?.toString()?.trim()?.toLowerCase() === 'select'
            );

            if (!defaultOption) {
                return { formFieldOptions: selectOptions, formFieldSelectedDefault: true };
            }
        }

        return { formFieldOptions: selectOptions, formFieldSelectedDefault: false };
    }

    manipulateRadioField(field: any) {
        const radioFieldOptions: any = this.getFormFieldOptions(field);
        return { formFieldOptions: radioFieldOptions };
    }

    manipulatCheckboxField(field: any) {
        const CheckboxFieldOptions: any = this.getFormFieldOptions(field);
        return { formFieldOptions: CheckboxFieldOptions };
    }

    getFormFieldOptions(field: any) {
        const options: any = field?.choices?.map((item: any) => {
            const option: any = {};
            const label = item?.text?.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
            [option.label, option.value] = [label, item.value];
            return option;
        });
        return options;
    }

    manipulateErrorValidators(field: any) {
        //NOSONAR
        const obj: any = { formFieldValidators: [], formFieldErrorMessages: {} };

        if ('isRequired' in field && field['isRequired']) {
            if (field?.__typename?.toString().toLowerCase() === 'consentfield') {
                //NOSONAR
                obj['formFieldValidators'].push(Validators.requiredTrue);
                obj['formFieldErrorMessages']['required'] = field?.errorMessage ?? 'The field is not valid';
            } else {
                obj['formFieldValidators'].push(Validators.required);
                obj['formFieldErrorMessages']['required'] = field?.errorMessage ?? 'The field is not valid';
            }
        } else {
            if (field?.__typename?.toString().toLowerCase() === 'captchafield') {
                //NOSONAR
                obj['formFieldValidators'].push(Validators.required);
                obj['formFieldErrorMessages']['required'] =
                    field?.errorMessage ?? 'please confirm that you are not a robot';
            } else {
                if (this.isFormFieldRequired(field)) {
                    //NOSONAR
                    field['isRequired'] = true;
                    obj['formFieldValidators'].push(Validators.required);
                    obj['formFieldErrorMessages']['required'] = field?.errorMessage ?? 'The field is not valid';
                }
            }
        }

        if ('maxLength' in field && field['maxLength'] && parseInt(field['maxLength']) > 0) {
            obj['formFieldValidators'].push(Validators.maxLength(parseInt(field['maxLength'])));
            obj['formFieldErrorMessages']['maxlength'] = field?.errorMessage ?? 'The field is not valid';
        } else {
            const label = field?.label?.toString()?.trim()?.toLowerCase();
            const labelArray = ['fax', 'fax number', 'fax no'];
            if (label && labelArray?.includes(label)) {
                obj['formFieldValidators'].push(Validators.maxLength(15));
                obj['formFieldErrorMessages']['maxlength'] = 'The field is not valid';
            }
        }

        if ('minLength' in field && field['minLength'] && parseInt(field['minLength']) >= 0) {
            obj['formFieldValidators'].push(Validators.minLength(parseInt(field['minLength'])));
            obj['formFieldErrorMessages']['minlength'] = field?.errorMessage ?? 'The field is not valid';
        } else {
            const fieldTypeName = field?.__typename?.toString()?.trim()?.toLowerCase();
            const minLength =
                fieldTypeName === 'phonefield'
                    ? 10
                    : fieldTypeName === 'ssnfield'
                    ? 9
                    : fieldTypeName === 'zipfield'
                    ? 5
                    : fieldTypeName === 'routingnumberfield'
                    ? 9
                    : null; //NOSONAR

            if (minLength !== null) {
                obj['formFieldValidators'].push(Validators.minLength(minLength));
                obj['formFieldErrorMessages']['minlength'] = field?.errorMessage ?? 'The field is not valid';
            }
        }

        if (field?.__typename?.toString().toLowerCase() === 'emailfield') {
            obj['formFieldValidators'].push(
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
            );
            obj['formFieldErrorMessages']['email'] = field?.errorMessage ?? 'The field is not valid';
            obj['formFieldErrorMessages']['pattern'] = field?.errorMessage ?? 'The field is not valid';
        }

        if (field?.__typename?.toString().toLowerCase() === 'websitefield') {
            //NOSONAR
            const urlPattern: any = /^(https?:\/\/www\.|http?:\/\/www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^/\s]*)*$/i; //NOSONAR
            obj['formFieldValidators'].push(Validators.pattern(urlPattern)); //NOSONAR
            obj['formFieldErrorMessages']['pattern'] = field?.errorMessage ?? 'Enter a valid URL';
        }

        if (field?.__typename?.toString().toLowerCase() === 'routingnumberfield') {
            obj['formFieldValidators'].push(CustomValidators.customChecksumValidator());
            obj['formFieldErrorMessages']['customChecksum'] = 'Bank routing number is not valid';
        }

        return obj;
    }

    manipulateFieldInputTypes(field: any) {
        const formField: any = { ...field };
        const inputType = formField?.__typename?.toString()?.trim()?.toLowerCase();
        const label = formField?.label?.toString()?.trim()?.toLowerCase();
        if (
            inputType &&
            inputType !== 'phonefield' &&
            label &&
            (label.includes('phone') || label.includes('mobile'))
        ) {
            formField['__typename'] = 'PhoneField';
            formField['type'] = 'PHONE';
            formField['inputType'] = 'PHONE';
            return formField;
        }

        if (label && (label === 'ssn' || label === 'ssn number' || label === 'ssn no')) {
            formField['__typename'] = 'SSNField';
            formField['type'] = 'TEXT';
            formField['inputType'] = 'TEXT';
            return formField;
        }

        if (label && label && (label.includes('zip') || label.includes('zip code'))) {
            formField['__typename'] = 'ZipField';
            formField['type'] = 'TEXT';
            formField['inputType'] = 'TEXT';
            return formField;
        }

        if (
            label &&
            label &&
            (label.includes('routing') ||
                label.includes('routing number') ||
                label.includes('aba (routing) no') ||
                label.includes('aba (routing) number'))
        ) {
            formField['__typename'] = 'RoutingNumberField';
            formField['type'] = 'TEXT';
            formField['inputType'] = 'TEXT';
            return formField;
        }

        return formField;
    }

    hideFormFieldInputText(field: any) {
        const label = field?.label?.toString()?.trim()?.toLowerCase();
        const labelArray = [
            'ssn',
            'ssn number',
            'ssn no',
            'federal tax id number',
            'federal tax id no',
            'federal tax id',
            'bank account number',
            'bank account no',
            'account number',
            'account no',
        ];
        if (label && labelArray?.includes(label)) {
            return { hideFormFieldText: true, isPasswordType: true };
        }
        return { hideFormFieldText: false, isPasswordType: false };
    }

    getFormFieldClass(field: any) {
        const column: any = field?.layoutGridColumnSpan || 12;
        if (field?.__typename?.toString().toLowerCase() === 'captchafield') {
            let classes: any = `col-12 col-md-${column} p-0 m-0 `;
            if (this.isFormFieldHidden(field)) {
                classes = `${classes} d-none `;
            }
            return classes;
        }
        if (field?.__typename?.toString().toLowerCase() === 'consentfield') {
            let classes: any = `col-12 col-md-${column} p-0 m-0 mb-4 mb-md-10 `;
            if (this.isFormFieldHidden(field)) {
                classes = `${classes} d-none `;
            }
            return classes;
        }
        let classes: any = `col-12 col-md-${column} mb-8 mb-md-10 `;
        if (this.isFormFieldHidden(field)) {
            classes = `${classes} d-none `;
        }
        return classes;
    }

    formFieldFloatingLabelTextClass(field: any) {
        return `section-form-field-label `;
    }

    getFormFieldContainerClass(field: any) {
        const cssClassString = field?.cssClass?.toString() || '';
        const cssClassArray = cssClassString.toLowerCase().split(/\s+/);

        return cssClassArray.includes('section-row') ? 'row m-0 p-0 ' : 'm-0 p-0 ';
    }

    getFormFieldInputLabelClass(field?: any) {
        if (
            field?.__typename?.toString().toLowerCase() === 'consentfield' ||
            field?.__typename?.toString().toLowerCase() === 'checkboxfield'
        ) {
            const classes: any = `check-box-label-class `;
            return classes;
        }
        return '';
    }

    getFormFieldInputContainerClass(field?: any) {
        if (
            field?.__typename?.toString().toLowerCase() === 'consentfield' ||
            field?.__typename?.toString().toLowerCase() === 'checkboxfield'
        ) {
            const classes: any = 'form-check text-start d-flex align-items-center check-box-container mt-0 ';
            return classes;
        }
        return '';
    }

    /**
     * @description TO display/hide section based on 'section-hide' class and
     * the section will be shown if the corresponding field Id value matchces with conditional logic.
     */
    isSectionDisplay(node: any) {
        const cssClassString = node?.cssClass?.toString() || '';
        const cssClassArray = cssClassString.toLowerCase().split(/\s+/);

        return !cssClassArray.includes('section-hide');
    }

    /**
     * @description To auto-fill the field values if the sectionChild is true and it has 'section-child-id' class and
     * the section field will be auto filled by parent values if the user checks the checkbox in the respective section childs.
     */
    isSectionChild(node: any) {
        const cssClassString = node?.cssClass?.toString() || '';
        const cssClassStringArray = cssClassString.split(/\s+/);

        const childSection = cssClassStringArray.find((str: string) => {
            const match = str.toLowerCase().match(/section-child-(\d+)/); //NOSONAR
            return match !== null;
        });

        if (childSection) {
            const childNumberMatch = childSection.match(/section-child-(\d+)/); //NOSONAR
            const childNumber = childNumberMatch ? parseInt(childNumberMatch[1], 10) : null;
            return [true, childNumber];
        }

        return [false, null];
    }

    /**
     * @description To add accordion functionality to the respective section if it has 'section-accordion-number' class.
     * if user clicks add another store button and the default value of post custom field greater than 1 then we need to display accordion sections.
     */
    isSectionAccordion(node: any) {
        const cssClassString = node?.cssClass?.toString() || '';
        const cssClassStringArray = cssClassString.split(/\s+/);

        const accordionSection = cssClassStringArray.find((str: string) => {
            const match = str.toLowerCase().match(/section-accordion-(\d+)/); //NOSONAR
            return match !== null;
        });

        if (accordionSection) {
            const accordionNumberMatch = accordionSection.match(/section-accordion-(\d+)/); //NOSONAR
            const accordionNumber = accordionNumberMatch ? parseInt(accordionNumberMatch[1], 10) : null;
            return [true, accordionNumber];
        }

        return [false, null];
    }

    /**
     * @description TO autofill form-fields in section based on 'section-auto-fill' class and
     * the section fields will be auto filled when the corresponding checkbox field in section is checked.
     */
    isFormFieldAutoFillCheckBox(node: any) {
        const cssClassString = node?.cssClass?.toString() || '';
        const cssClassArray = cssClassString.toLowerCase().split(/\s+/);

        return cssClassArray.includes('section-auto-fill');
    }

    isFormFieldRequired(field: any) {
        const cssClassString = field?.cssClass?.toString() || '';
        const cssClassArray = cssClassString.toLowerCase().split(/\s+/);

        return cssClassArray.includes('field-required');
    }

    isFormFieldHidden(field: any) {
        const cssClassString = field?.cssClass?.toString() || '';
        const cssClassArray = cssClassString.toLowerCase().split(/\s+/);

        return cssClassArray.includes('field-hidden');
    }

    getFieldMask(field: any) {
        const inputType = field?.__typename?.toString()?.trim()?.toLowerCase();
        const label = field?.label?.toString()?.trim()?.toLowerCase();
        if (
            (inputType && inputType === 'phonefield') ||
            (label && (label.includes('phone') || label.includes('mobile')))
        ) {
            return '000-000-0000';
        }
        if (inputType && inputType === 'ssnfield') {
            return '000-00-0000';
        }
        if (inputType && inputType === 'zipfield') {
            return '00000';
        }
        if (
            (inputType && inputType === 'routingnumberfield') ||
            (label &&
                (label.includes('routing') ||
                    label.includes('routing number') ||
                    label.includes('aba (routing) no') ||
                    label.includes('aba (routing) number')))
        ) {
            return '000000000';
        }

        return null;
    }

    addReCaptchaState(value: boolean): void {
        const captchaState = new BehaviorSubject<boolean>(value);
        this.reCaptchaStates.push(captchaState);
    }

    getReCaptchaState(index: number): Observable<boolean> {
        return this.reCaptchaStates[index].asObservable();
    }

    updateCaptchaState(index: number, value: boolean): void {
        this.reCaptchaStates[index].next(value);
    }

    getNativeFormModuleData(ddGfFormIds: string): any {
        const formIds = { formIds: ddGfFormIds };
        return this._apollo.query<any>({
            query: GFORM_DATA_QUERY,
            variables: { ...formIds },
        });
    }
}

export class CustomValidators extends Validators {
    static customChecksumValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value: string = control.value;
            let bResult = false;
            let iChecksum = 0;

            // Check if minlength validation is satisfied
            if (value?.length === 9) {
                // Calculate checksum
                for (let iIdx = 0; iIdx < value.length; iIdx += 3) {
                    iChecksum +=
                        parseInt(value.charAt(iIdx), 10) * 3 +
                        parseInt(value.charAt(iIdx + 1), 10) * 7 +
                        parseInt(value.charAt(iIdx + 2), 10);
                }

                // If the checksum is an even multiple of 10 and not 0 then the number is considered valid
                if (iChecksum !== 0 && iChecksum % 10 === 0) {
                    bResult = true;
                }

                // Return validation error if the number is not valid
                return bResult ? null : { customChecksum: true };
            }
            return null;
        };
    }
}
