import { formatDate } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

interface YearSelection {
    element: HTMLDivElement | null;
    year: number;
}
interface DobDateSelection {
    element: HTMLDivElement | null;
    date: string;
}

interface DateRangeSelection {
    element: HTMLDivElement | null;
    date: Date | null;
}

interface SelectedDateRange {
    start: DateRangeSelection;
    end: DateRangeSelection;
}

enum calendarTypeEnum {
    'date-range' = 'date-range',
    'date-of-birth' = 'date-of-birth',
    'pay-day' = 'pay-day',
}

@Component({
    selector: 'ua2-corporate-calendar, aff-corporate-calendar',
    templateUrl: './corporate-calendar.component.html',
})
export class CorporateCalendarComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() calendarType: string;
    @Input() dateFormat = 'yyyy-MM-dd';
    @Input() gridRowSize = 6;
    @Input() gridColumnSize = 4;
    @Input() lifeExpectancy: number;
    // number of days
    @Input() dateRangeDuration: number;
    // number of months
    @Input() paydayMonthsDuration: number;
    // list of holidays for the selected months duration
    @Input() holidayCalendar: string[];
    @Input() set showCalendar(val: boolean) {
        if (val) {
            this.hideYearListView();
        }
    }

    @Input() set dateEntered(val: string) {
        //NOSONAR
        if (val) {
            this.hideYearListView();
            if (
                (this.calendarType === calendarTypeEnum['date-of-birth'] ||
                    this.calendarType === calendarTypeEnum['pay-day']) &&
                val != this.dateSelected
            ) {
                const dateSplit = val.split('-');
                this.currentMonthIndex = Number(dateSplit[0]) - 1;
                this.currentYear = Number(dateSplit[2]);
                this.prevSelectedYear.year = this.currentYear;
                this.generateCalendar(this.currentMonthIndex, this.currentYear);
                const dayDiv = this.calendarDaysRef.nativeElement.querySelector(`div[data-date="${val}"]`);
                if (dayDiv) {
                    this.onCalendarDayClick(dayDiv);
                }
            } else if (this.calendarType === calendarTypeEnum['date-range'] && val != this.dateSelected) {
                const dateSplit = val.split('-');
                let startDateString = dateSplit[0].trim();
                let endDateString = dateSplit[1].trim();
                let start = new Date(startDateString);
                let end = new Date(endDateString);

                if (start > end) {
                    [start, end] = [end, start];
                    [startDateString, endDateString] = [endDateString, startDateString];
                }

                this.currentMonthIndex = start.getMonth();
                this.currentYear = start.getFullYear();

                this.generateCalendar(this.currentMonthIndex, this.currentYear);

                const startDiv = this.calendarDaysRef.nativeElement.querySelector(
                    `div[data-date="${formatDate(startDateString, this.dateFormat, 'en-US')}"]`
                );
                if (startDiv) {
                    this.onCalendarDayClick(startDiv);
                }

                this.currentMonthIndex = end.getMonth();
                this.currentYear = end.getFullYear();

                this.prevSelectedYear.year = this.currentYear;
                this.generateCalendar(this.currentMonthIndex, this.currentYear);

                const endDiv = this.calendarDaysRef.nativeElement.querySelector(
                    `div[data-date="${formatDate(endDateString, this.dateFormat, 'en-US')}"]`
                );
                if (endDiv) {
                    this.onCalendarDayClick(endDiv);
                }
            }
        } else {
            this.dateSelected = '';
            if (this.applyButtonRef) {
                this.renderer.removeClass(this.applyButtonRef.nativeElement, 'aff-apply-button-active');
            }
            if (
                this.calendarType === calendarTypeEnum['date-of-birth'] ||
                this.calendarType === calendarTypeEnum['pay-day']
            ) {
                this.togglePrevDobSelection();
            } else if (this.calendarType === calendarTypeEnum['date-range']) {
                if (!this.dateRangeSelection.end.element) {
                    this.dateRangeSelection.end.element = this.dateRangeSelection.start.element;
                    this.dateRangeSelection.end.date = this.dateRangeSelection.start.date;
                }
                this.clearPreviousRangeSelection();
            }

            this.togglePrevYearSelection();
        }
    }

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onDateSelection = new EventEmitter<string>();

    daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    currentMonthIndex: number;
    currentYear: number;
    gridSize: number;
    yearsListMin = 0;
    yearsListMax = 0;
    isYearListView = false;
    dateSelected: string;
    daysCount = 37;
    prevSelectedDate: DobDateSelection = {
        element: null,
        date: '',
    };
    prevSelectedYear: YearSelection = {
        element: null,
        year: 0,
    };
    dateRangeSelection: SelectedDateRange = {
        start: {
            element: null,
            date: null,
        },
        end: {
            element: null,
            date: null,
        },
    };
    start = new Date();
    end = new Date();

    @ViewChild('calendarDaysRef') calendarDaysRef!: ElementRef;
    @ViewChild('yearsListRef') yearsListRef!: ElementRef;
    @ViewChild('selectedDateLabel') selectedDateLabel!: ElementRef;
    @ViewChild('applyButtonRef') applyButtonRef!: ElementRef;

    constructor(private renderer: Renderer2) {}

    get caretIcon() {
        return 'fa fa-caret-down';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['lifeExpectancy']) {
            if (
                (this.calendarType && this.calendarType === calendarTypeEnum['date-of-birth']) ||
                changes['calendarType']?.currentValue === calendarTypeEnum['date-of-birth']
            ) {
                this.start.setFullYear(this.start.getFullYear() - changes['lifeExpectancy'].currentValue);
            }
        }

        if (changes['dateRangeDuration']) {
            if (
                (this.calendarType && this.calendarType === calendarTypeEnum['date-range']) ||
                changes['calendarType'].currentValue === calendarTypeEnum['date-range']
            ) {
                this.end.setDate(this.end.getDate() + changes['dateRangeDuration'].currentValue);
            }
        }

        if (changes['paydayMonthsDuration']) {
            if (
                (this.calendarType && this.calendarType === calendarTypeEnum['pay-day']) ||
                changes['calendarType'].currentValue === calendarTypeEnum['pay-day']
            ) {
                this.end.setDate(-1);
                this.end.setMonth(this.end.getMonth() + changes['paydayMonthsDuration'].currentValue);
            }
        }
    }

    ngOnInit(): void {
        this.gridSize = this.gridColumnSize * this.gridRowSize;
        this.start.setHours(0, 0, 0, 0);
        this.end.setHours(0, 0, 0, 0);
    }

    ngAfterViewInit() {
        const dayDivs = this.calendarDaysRef.nativeElement.children;
        for (let i = 0; i < dayDivs.length; i++) {
            //NOSONAR
            this.renderer.listen(dayDivs[i], 'click', this.onCalendarDayClick.bind(this, dayDivs[i]));
        }

        const yearDivs = this.yearsListRef.nativeElement.children;
        for (let i = 0; i < yearDivs.length; i++) {
            //NOSONAR
            this.renderer.listen(yearDivs[i], 'click', this.onYearInListClick.bind(this, yearDivs[i]));
        }

        this.generateCurrentDayCalendar();
    }

    checkLeapYear(year: number) {
        return (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) || (year % 100 === 0 && year % 400 === 0);
    }

    getFebDays(year: number) {
        return this.checkLeapYear(year) ? 29 : 28;
    }

    dobPaydayCalendarDayClick = (element: HTMLDivElement) => {
        this.dateSelected = formatDate(element.getAttribute('data-date')!, this.dateFormat, 'en-US');
        this.togglePrevDobSelection();

        this.renderer.addClass(element, 'aff-calendar-selected-day');
        this.renderer.addClass(this.applyButtonRef.nativeElement, 'aff-apply-button-active');

        this.prevSelectedDate.element = element;
        this.prevSelectedDate.date = this.dateSelected;
    };

    dateRangeCalendarDayClick = (element: HTMLDivElement) => {
        if (!this.dateRangeSelection.start.date) {
            this.dateRangeSelection.start.date = new Date(
                this.currentYear,
                this.currentMonthIndex,
                Number(element.getAttribute('data-day'))
            );

            this.dateRangeSelection.start.element = element;
            const date = formatDate(element.getAttribute('data-date')!, this.dateFormat, 'en-US');
            this.dateSelected = date + ' - ' + date;
            this.renderer.addClass(this.dateRangeSelection.start.element, 'aff-calendar-selected-day');
            this.renderer.addClass(this.applyButtonRef.nativeElement, 'aff-apply-button-active');
            return;
        }

        const second = new Date(element.getAttribute('data-date')!);
        if (this.dateRangeSelection.start.date > second) {
            const temp = second;
            this.dateRangeSelection.end.date = this.dateRangeSelection.start.date;
            this.dateRangeSelection.start.date = temp;

            this.dateRangeSelection.end.element = this.dateRangeSelection.start.element;
            this.dateRangeSelection.start.element = element;
        } else {
            this.dateRangeSelection.end.element = element;
            this.dateRangeSelection.end.date = second;
        }

        const dayDivs = this.calendarDaysRef.nativeElement.children;

        for (let i = 0; i < dayDivs.length; i++) {
            //NOSONAR
            const date = dayDivs[i].getAttribute('data-date');
            if (date) {
                const divDate = new Date(date);
                if (divDate >= this.dateRangeSelection.start.date && divDate <= this.dateRangeSelection.end.date) {
                    this.renderer.addClass(dayDivs[i], 'aff-calendar-day-range-selection');
                }
            }
        }

        this.dateSelected =
            formatDate(this.dateRangeSelection.start.date, this.dateFormat, 'en-US') +
            ' - ' +
            formatDate(this.dateRangeSelection.end.date, this.dateFormat, 'en-US');

        this.renderer.removeClass(this.dateRangeSelection.start.element, 'aff-calendar-selected-day');
        this.renderer.removeClass(this.dateRangeSelection.end.element, 'aff-calendar-selected-day');
        this.renderer.setProperty(
            this.dateRangeSelection.start.element?.children[0],
            'innerHTML',
            this.dateRangeSelection.start.element!.getAttribute('data-day')
        );
        this.renderer.setProperty(
            this.dateRangeSelection.end.element?.children[0],
            'innerHTML',
            this.dateRangeSelection.end.element!.getAttribute('data-day')
        );

        if (
            this.dateRangeSelection.start.date?.getFullYear() === this.currentYear &&
            this.dateRangeSelection.start.date?.getMonth() === this.currentMonthIndex
        ) {
            this.renderer.addClass(
                this.dateRangeSelection.start.element,
                'aff-calendar-day-range-selection-start'
            );
        }

        if (
            this.dateRangeSelection.end.date?.getFullYear() === this.currentYear &&
            this.dateRangeSelection.end.date?.getMonth() === this.currentMonthIndex
        ) {
            this.renderer.addClass(this.dateRangeSelection.end.element, 'aff-calendar-day-range-selection-end');
        }
    };

    clearPreviousRangeSelection() {
        if (this.dateRangeSelection.start.element && this.dateRangeSelection.end.element) {
            this.renderer.removeClass(
                this.dateRangeSelection.start.element,
                'aff-calendar-day-range-selection-start'
            );
            this.renderer.removeClass(this.dateRangeSelection.end.element, 'aff-calendar-day-range-selection-end');
            this.renderer.removeClass(this.dateRangeSelection.start.element, 'aff-calendar-selected-day');
            this.renderer.removeClass(this.dateRangeSelection.end.element, 'aff-calendar-selected-day');
            this.renderer.removeClass(this.applyButtonRef.nativeElement, 'aff-apply-button-active');
            const dayDivs = this.calendarDaysRef.nativeElement.children;

            for (let i = 0; i < dayDivs.length; i++) {
                //NOSONAR
                const date = dayDivs[i].getAttribute('data-date');
                if (date) {
                    const divDate = new Date(date);
                    if (
                        divDate >= this.dateRangeSelection.start.date! &&
                        divDate <= this.dateRangeSelection.end.date!
                    ) {
                        this.renderer.removeClass(dayDivs[i], 'aff-calendar-day-range-selection');
                    }
                }
            }

            this.renderer.setProperty(
                this.dateRangeSelection.start.element.children[0],
                'innerHTML',
                this.dateRangeSelection.start.element.getAttribute('data-day')
            );
            this.renderer.setProperty(
                this.dateRangeSelection.end.element.children[0],
                'innerHTML',
                this.dateRangeSelection.end.element.getAttribute('data-day')
            );

            this.dateRangeSelection.start.element = null;
            this.dateRangeSelection.start.date = null;
            this.dateRangeSelection.end.element = null;
            this.dateRangeSelection.end.date = null;
            this.dateSelected = '';
        }
    }

    togglePrevDateRangeSelection() {
        //NOSONAR
        if (this.dateRangeSelection.start.element) {
            this.renderer.setProperty(
                this.dateRangeSelection.start.element.children[0],
                'innerHTML',
                this.dateRangeSelection.start.element.getAttribute('data-day')
            );

            if (
                this.dateRangeSelection.start.date?.getFullYear() === this.currentYear &&
                this.dateRangeSelection.start.date?.getMonth() === this.currentMonthIndex
            ) {
                this.renderer.addClass(
                    this.dateRangeSelection.start.element,
                    this.dateRangeSelection.end.element
                        ? 'aff-calendar-day-range-selection-start'
                        : 'aff-calendar-selected-day'
                );
            } else {
                this.renderer.removeClass(
                    this.dateRangeSelection.start.element,
                    this.dateRangeSelection.end.element
                        ? 'aff-calendar-day-range-selection-start'
                        : 'aff-calendar-selected-day'
                );
            }
        }

        if (this.dateRangeSelection.end.element) {
            this.renderer.setProperty(
                this.dateRangeSelection.end.element.children[0],
                'innerHTML',
                this.dateRangeSelection.end.element.getAttribute('data-day')
            );

            if (
                this.dateRangeSelection.end.date?.getFullYear() === this.currentYear &&
                this.dateRangeSelection.end.date?.getMonth() === this.currentMonthIndex
            ) {
                this.renderer.addClass(
                    this.dateRangeSelection.end.element,
                    'aff-calendar-day-range-selection-end'
                );
            } else {
                this.renderer.removeClass(
                    this.dateRangeSelection.end.element,
                    'aff-calendar-day-range-selection-end'
                );
            }
        }

        if (this.dateRangeSelection.start.element && this.dateRangeSelection.end.element) {
            const dayDivs = this.calendarDaysRef.nativeElement.children;

            for (let i = 0; i < dayDivs.length; i++) {
                //NOSONAR
                const date = dayDivs[i].getAttribute('data-date');
                if (date) {
                    const divDate = new Date(date);
                    if (
                        divDate >= this.dateRangeSelection.start.date! &&
                        divDate <= this.dateRangeSelection.end.date!
                    ) {
                        this.renderer.addClass(dayDivs[i], 'aff-calendar-day-range-selection');
                    } else {
                        this.renderer.removeClass(dayDivs[i], 'aff-calendar-day-range-selection');
                    }
                }
            }
        }
    }

    onCalendarDayClick = (element: HTMLDivElement) => {
        if (
            this.calendarType === calendarTypeEnum['date-of-birth'] ||
            this.calendarType === calendarTypeEnum['pay-day']
        ) {
            this.dobPaydayCalendarDayClick(element);
        } else if (this.calendarType === calendarTypeEnum['date-range']) {
            this.clearPreviousRangeSelection();
            this.dateRangeCalendarDayClick(element);
        }
    };

    togglePrevDobSelection() {
        if (this.prevSelectedDate.element) {
            const date = new Date(this.prevSelectedDate.date);
            if (
                date.getMonth() === this.currentMonthIndex &&
                date.getFullYear() === this.currentYear &&
                date.getDate() === new Date(this.dateSelected).getDate()
            ) {
                this.renderer.addClass(this.prevSelectedDate.element, 'aff-calendar-selected-day');
            } else {
                this.renderer.removeClass(this.prevSelectedDate.element, 'aff-calendar-selected-day');
            }
        }
    }

    onApplyClick() {
        this.onDateSelection.emit(this.dateSelected);
    }

    hideYearListView() {
        if (this.isYearListView) {
            this.isYearListView = false;
            this.renderer.removeClass(this.yearsListRef.nativeElement, 'aff-calendar-show-years');
        }
    }

    generateCurrentDayCalendar() {
        this.hideYearListView();

        const date = new Date();
        this.currentMonthIndex = date.getMonth();
        this.currentYear = date.getFullYear();
        this.generateCalendar(this.currentMonthIndex, this.currentYear);
    }

    generateCalendar(month: number, year: number) {
        //NOSONAR
        const curDate = new Date();
        curDate.setHours(0, 0, 0, 0);

        if (month > 11 || month < 0) {
            month = curDate.getMonth();
        }

        if (!year) {
            year = curDate.getFullYear();
        }

        this.daysOfMonth[1] = this.getFebDays(year);
        const curCalendarDay = new Date(year, month, 1);
        const firstDayIndex = curCalendarDay.getDay();
        const contentDivs = this.calendarDaysRef.nativeElement.children;
        const length = this.daysOfMonth[month] + firstDayIndex;

        for (let i = 0; i < length; i++) {
            this.renderer.setProperty(contentDivs[i], 'classList', []);
            this.renderer.setAttribute(contentDivs[i], 'data-day', '');
            this.renderer.setAttribute(contentDivs[i], 'data-date', '');

            if (i >= firstDayIndex) {
                const dayVal = i - firstDayIndex + 1;
                const curDayDate = new Date(this.currentYear, this.currentMonthIndex, dayVal);
                const curDay = formatDate(curDayDate, this.dateFormat, 'en-US');
                this.renderer.setProperty(contentDivs[i].children[0], 'innerHTML', String(dayVal));
                this.renderer.setAttribute(contentDivs[i], 'data-day', String(dayVal));
                this.renderer.setAttribute(contentDivs[i], 'data-date', curDay);

                curCalendarDay.setDate(dayVal);

                if (
                    year === curDate.getFullYear() &&
                    month === curDate.getMonth() &&
                    curDate.getDate() === dayVal
                ) {
                    this.renderer.addClass(contentDivs[i], 'aff-calendar-current-day');
                } else {
                    this.renderer.removeClass(contentDivs[i], 'aff-calendar-current-day');
                }

                if (
                    curCalendarDay >= new Date(1800, 0, 1) &&
                    curCalendarDay <= this.end &&
                    ((this.calendarType === calendarTypeEnum['pay-day'] &&
                        !this.holidayCalendar.includes(curDay) &&
                        !(curDayDate.getDay() === 6 || curDayDate.getDay() === 0)) ||
                        this.calendarType === calendarTypeEnum['date-of-birth'] ||
                        this.calendarType === calendarTypeEnum['date-range'])
                ) {
                    this.renderer.addClass(contentDivs[i], 'aff-calendar-day-active');
                }
            } else {
                this.renderer.setProperty(contentDivs[i].children[0], 'innerHTML', '');
            }
        }

        for (let i = length; i < this.daysCount; i++) {
            this.renderer.setProperty(contentDivs[i], 'classList', []);
            this.renderer.setProperty(contentDivs[i].children[0], 'innerHTML', '');
            this.renderer.setAttribute(contentDivs[i], 'data-day', '');
            this.renderer.setAttribute(contentDivs[i], 'data-date', '');
        }
        this.setSelectedDate();
    }

    onYearInListClick = (element: HTMLDivElement) => {
        const year = Number(element.getAttribute('data-year'));
        this.isYearListView = false;
        this.renderer.removeClass(this.yearsListRef.nativeElement, 'aff-calendar-show-years');
        this.currentYear = year;
        this.generateCalendar(this.currentMonthIndex, this.currentYear);
        this.togglePrevYearSelection();
        this.renderer.addClass(element, 'aff-calendar-selected-year');
        this.prevSelectedYear.element = element;
        this.prevSelectedYear.year = year;
    };

    togglePrevYearSelection() {
        if (this.prevSelectedYear.element) {
            if (
                this.prevSelectedYear.year >= this.yearsListMin &&
                this.prevSelectedYear.year <= this.yearsListMax &&
                this.prevSelectedYear.year === this.currentYear
            ) {
                this.renderer.addClass(this.prevSelectedYear.element, 'aff-calendar-selected-year');
            } else {
                this.renderer.removeClass(this.prevSelectedYear.element, 'aff-calendar-selected-year');
            }
        }
    }

    generateYearsListView(start: number) {
        //NOSONAR
        this.yearsListRef.nativeElement.style.setProperty('--grid-column-size', this.gridColumnSize);
        let yearMax = 0;
        const curDate = new Date();
        curDate.setHours(0, 0, 0, 0);
        const yearDivs = this.yearsListRef.nativeElement.children;
        //NOSONAR
        for (let i = start, j = 0; i < start + this.gridSize, j < this.gridSize; i++, j++) {
            //NOSONAR
            this.renderer.setProperty(yearDivs[j], 'classList', []);
            this.renderer.setProperty(yearDivs[j], 'innerHTML', String(i));
            this.renderer.setAttribute(yearDivs[j], 'data-year', String(i));

            yearMax = i;

            if (i <= this.end.getFullYear() && i >= 1800) {
                this.renderer.addClass(yearDivs[j], 'aff-calendar-years-active');
            }

            if (i == curDate.getFullYear()) {
                this.renderer.addClass(yearDivs[j], 'aff-calendar-current-year');
            }

            if (
                this.prevSelectedYear.year === i &&
                !yearDivs[j].classList.contains('aff-calendar-selected-year')
            ) {
                this.prevSelectedYear.element = yearDivs[j];
                this.renderer.addClass(yearDivs[j], 'aff-calendar-selected-year');
            }
        }

        this.yearsListMin = start;
        this.yearsListMax = yearMax;

        this.renderer.addClass(this.yearsListRef.nativeElement, 'aff-calendar-show-years');
        this.togglePrevYearSelection();
    }

    headerYearClicked() {
        let colNum = this.currentYear % this.gridColumnSize;
        if (this.currentYear % this.gridColumnSize === 0) {
            colNum = this.gridColumnSize;
        }

        let rowNum = Math.ceil((this.currentYear % this.gridSize) / this.gridColumnSize);
        if (this.currentYear % this.gridSize === 0) {
            rowNum = this.gridRowSize;
        }

        const start = this.currentYear - ((rowNum - 1) * this.gridColumnSize + (colNum - 1));
        this.isYearListView = true;
        this.generateYearsListView(start);
    }

    prevNavigatorClicked() {
        if (this.isYearListView) {
            this.prevYearClicked();
            return;
        }
        if (this.currentMonthIndex === 0) {
            this.currentMonthIndex = 11;
            this.currentYear -= 1;
            if (this.currentYear >= this.start.getFullYear() && this.currentYear <= this.end.getFullYear()) {
                this.prevSelectedYear.year = this.currentYear;
            }
        } else {
            this.currentMonthIndex -= 1;
        }

        this.generateCalendar(this.currentMonthIndex, this.currentYear);
    }

    nextNavigatorClicked() {
        if (this.isYearListView) {
            this.nextYearClicked();
            return;
        }
        if (this.currentMonthIndex === 11) {
            this.currentMonthIndex = 0;
            this.currentYear += 1;
            if (this.currentYear >= this.start.getFullYear() && this.currentYear <= this.end.getFullYear()) {
                this.prevSelectedYear.year = this.currentYear;
            }
        } else {
            this.currentMonthIndex += 1;
        }

        this.generateCalendar(this.currentMonthIndex, this.currentYear);
    }

    setSelectedDate() {
        this.renderer.setProperty(
            this.selectedDateLabel.nativeElement,
            'textContent',
            this.months[this.currentMonthIndex] + ' ' + this.currentYear
        );
        this.calendarType === calendarTypeEnum['date-range']
            ? this.togglePrevDateRangeSelection()
            : this.togglePrevDobSelection();
        this.togglePrevYearSelection();
        if (this.dateSelected) {
            this.renderer.addClass(this.applyButtonRef.nativeElement, 'aff-apply-button-active');
        }
    }

    prevYearClicked() {
        const start = this.yearsListMin - this.gridColumnSize * this.gridRowSize;
        this.generateYearsListView(start);
    }

    nextYearClicked() {
        const start = this.yearsListMax + 1;
        this.generateYearsListView(start);
    }
}
