import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ModalService } from '@janet/corporate/ui-corporate';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'janet-exit-site-confirmation',
    templateUrl: './exit-site-confirmation.component.html',
    styleUrls: [],
})
export class ExitSiteConfirmationComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    externalUrl: string;
    @Input() popUpData: any;
    @Input() popUpModule!: string;
    @ViewChild('continueBtn') continueBtn: ElementRef;
    @ViewChild('cancelBtn') cancelBtn: ElementRef;

    private affModalHide = {
        showModal: false,
    };

    constructor(
        private modalService: ModalService,
        public route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        this.subscription = this.modalService.action$.pipe(delay(300)).subscribe((affModalData: any) => {
            if (affModalData && affModalData.data && affModalData.data.popUpData) {
                this.popUpData = affModalData.data.popUpData.popUpModule;
            }
            const storedExitData = this.document?.defaultView?.sessionStorage?.getItem(this.popUpData);

            if (storedExitData) {
                try {
                    this.popUpData = JSON.parse(storedExitData);
                } catch (error) {
                    console.error('Error parsing stored exit data:', error);
                }
            }
            this.externalUrl = affModalData.exterenalUrl;
        });
    }

    closeModal(): void {
        this.modalService.modalAction(this.affModalHide);
    }

    onCancelBtnClick(): boolean {
        this.closeModal();
        return false;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
